import React, { useState } from 'react';
import { ActivitiesData } from '../assets/data/ActivitiesData';
import ActivityItem from './ActivityItem';

const ActivitiesContainer = () => {
    const [expandedIndex, setExpandedIndex] = useState(null); // Track which activity is expanded

    const handleTextClick = (index) => {
        // Toggle the expanded index
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <div>
            <h1 className="text-4xl font-bold mt-8 underline decoration-black">Preschool Activities</h1>
            <p className="text-lg mt-4">
                Here are some engaging preschool activities you can include in your homeschooling:
            </p>

            {ActivitiesData.map((activity, index) => (
                <div key={index} className="mt-6">
                    <ActivityItem
                        title={activity.title}
                        description={
                            <span
                                className="cursor-pointer text-black"
                                onClick={() => handleTextClick(index)} // Clickable description
                            >
                                {activity.description}
                            </span>
                        }
                        photos={activity.images} // Using external links directly
                        videos={activity.videos}
                        activityDetails={activity.activityDetails} 
                    />
                    {/* Show additional details if the activity is expanded */}
                    {/* {expandedIndex === index && (
                        <div className="mt-2">
                            {activity.activityDetails.map((detail, detailIndex) => (
                                <p key={detailIndex}></p>
                            ))}
                        </div>
                    )} */}
                </div>
            ))}
        </div>
    );
};

export default ActivitiesContainer;
