import React from "react";
import ScrollToTopButton from "../components/scrollTopButton";
import WeeklyPlanComponent from "../components/WeeklyPlanComponent";
import { WeeklyPlanData } from "../assets/data/WeeklyPlanData.";

const WeeklyPlan = () => {
    return (
        <div className="mt-44"> {/* Adjust the top margin */}
            <div className="text-center md:text-left md:ml-12">
                <h1 className="text-2xl font-bold mb-2">Weekly Plan</h1>
                <span className="block w-32 h-1 bg-gradient-to-r from-blue-500 to-purple-500 mt-1 mb-4 mx-auto md:mx-0"></span>
            </div>

            {/* Single Image for Curriculum with centered alignment on mobile */}
            <div className="flex flex-wrap gap-4 justify-center md:justify-start md:ml-12"> {/* Center images on mobile */}
                {WeeklyPlanData.map((pdf, index) => (
                    <WeeklyPlanComponent
                        key={index}
                        title={pdf.title}
                        weekNumber={pdf.weekNumber}
                        path={pdf.path}
                    />
                ))}
            </div>

            {/* Circular button to scroll to top */}
            <ScrollToTopButton />
        </div>
    );
};

export default WeeklyPlan;
