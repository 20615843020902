import React from "react";

const BooksDataComponent = ({ title, thumbnail, path }) => {
    return (
        <div className="max-w-xs w-full sm:w-1/2 md:w-1/3 lg:w-1/4 bg-white shadow-lg rounded-lg overflow-hidden p-4 m-2 flex flex-col">
            <img
                src={thumbnail}
                alt={`Free homeschool ${title} book`}
                className="w-full h-48 object-cover"
            />
            <h2 className="text-lg font-semibold text-gray-800 mb-2 text-center">
                {title}
            </h2>
            <div className="flex justify-between mt-2 w-full px-2">
                <a
                    href={path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 text-sm transition-transform transform hover:scale-110 animate-fade-in"
                >
                    View
                </a>
                <a
                    href={path}
                    download
                    className="text-blue-600 hover:text-blue-800 text-sm transition-transform transform hover:scale-110 animate-fade-in"
                >
                    Download
                </a>
            </div>
        </div>
    );
};

export default BooksDataComponent;
