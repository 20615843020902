import React from "react";
import PrintableContainer from "../components/PrintableContainer";

const Printable = () => {
    return (
        <div className="mt-32 ml-12">
            <PrintableContainer isMainPage={true} />
        </div>
    );
};

export default Printable;
