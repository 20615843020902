import React from "react";
import { gamesData } from "../assets/data/GamesData";
import ScrollToTopButton from "../components/scrollTopButton";

const Games = () => {
    return (
        <div className="App bg-gray-50 min-h-screen p-6 mt-36">
            <h1 className="text-3xl font-semibold text-center mb-10">Preschool Learning Games</h1>
            <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {gamesData.map((game) => (
                    <div key={game.id} className="game-card bg-white shadow-lg rounded-lg overflow-hidden p-4">
                        <img
                            src={game.thumbnail}
                            alt={`Free homeschool ${game.title} game`}
                            className="w-full h-48 object-cover rounded-md mb-4"
                        />
                        <h2 className="text-xl font-bold mb-2 text-center">{game.title}</h2>
                        <div className="download-links flex justify-center gap-4 mt-4">
                            <a
                                href={game.googlePlayLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-block"
                            >
                                <img
                                    src={require("../assets/images/google play.png")}
                                    alt="Google Play"
                                    className="w-24"
                                />
                            </a>
                            <a
                                href={game.appStoreLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-block"
                            >
                                <img
                                    src={require("../assets/images/app store.png")}
                                    alt="App Store"
                                    className="w-24"
                                />
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            {/* Circular button to scroll to top */}
            <ScrollToTopButton />
        </div>
    );
};

export default Games;
