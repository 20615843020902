export const learningVideos = [
    {
        title: "Moose Clumps",
        channelUrl: "https://youtube.com/@mooseclumps?feature=shared",
        channelImage: "https://yt3.googleusercontent.com/71oX8kONPwBgE4P_Vca7rpRZ18xC9IF8NVG2t2WVu1O8jklHO-QWNtjt7ErPY6yXE1hho1a3NA=s160-c-k-c0x00ffffff-no-rj" // Channel Image for Moose Clumps
    },
    {
        title: "Ms. Rachel",
        videoUrl: "https://www.youtube.com/watch?v=example_video_id",
        channelUrl: "https://youtube.com/@msrachel?feature=shared",
        channelImage: "https://yt3.googleusercontent.com/GmbCfVkiCuvAUtiYtSwNhe_EHFQCZgStpdp1DyDoDvudQWwfylSTKzHyt5NfyKLgt3htmzEdtg=s160-c-k-c0x00ffffff-no-rj" // Channel Image for Ms. Rachel
    },
    {
        title: "RV App Studios",
        videoUrl: "https://www.youtube.com/watch?v=example_video_id",
        channelUrl: "https://youtube.com/@rvappstudios?feature=shared",
        channelImage: "https://yt3.googleusercontent.com/I6xFCQW7yz3Lrw3GfeXk-FEunPj71gL798MbIOS99gSAbJEU7jxxZUjzoenmRh2bjgZexB31=s160-c-k-c0x00ffffff-no-rj" // Channel Image for RV App Studios
    },
    {
        title: "Super Simple Songs",
        videoUrl: "https://www.youtube.com/watch?v=example_video_id",
        channelUrl: "https://youtube.com/@supersimplesongs?si=KrJDWBn_XCUKjOmn",
        channelImage: "https://yt3.googleusercontent.com/wfO1-J_V0yO6VW2F0MKGdvORISTzvdIWmfV8ghkZpHH4nOLHhARqFsBNVp4Ey9_cphHcq8zTWQ=s160-c-k-c0x00ffffff-no-rj" // Channel Image for Super Simple Songs
    },
    {
        title: "Preschool Prep",
        videoUrl: "https://www.youtube.com/watch?v=example_video_id",
        channelUrl: "https://youtube.com/@preschoolprepcompany?si=o_W6GsxefBNRnUKY",
        channelImage: "https://yt3.googleusercontent.com/ytc/AIdro_mdIhxFt1UmP82glo0z1114-Ec0C_ks_A_mv6feH0ErIUc=s160-c-k-c0x00ffffff-no-rj" // Channel Image for Mera Preschool
    },
    {
        title: "Dream English Kids",
        videoUrl: "https://www.youtube.com/watch?v=example_video_id",
        channelUrl: "https://youtube.com/@dreamenglishkids?feature=shared",
        channelImage: "https://yt3.googleusercontent.com/ytc/AIdro_mMWSn5EMfdyQBKZ03a6Fh9my3mX_loCknCRUzyhnWWH5Y=s160-c-k-c0x00ffffff-no-rj" // Channel Image for Dream English Kids
    },
    {
        title: "Kids Academy",
        videoUrl: "https://www.youtube.com/watch?v=example_video_id",
        channelUrl: "https://youtube.com/@kidsacademycom?si=QLSrwkxTZaSzfCgR",
        channelImage: "https://yt3.googleusercontent.com/EetNfJ4jfzgO9u8_bP9Wx7CTBsu6Sv_xZz3zFwJusK1bqrp7j86r4d7s_QHchvwzLHiL-GdNjA=s160-c-k-c0x00ffffff-no-rj" // Channel Image for Kids Academy
    },
    {
        title: "Teddy and Timmy 3D",
        videoUrl: "https://www.youtube.com/watch?v=example_video_id",
        channelUrl: "https://youtube.com/@teddyandtimmy3d?feature=shared",
        channelImage: "https://yt3.googleusercontent.com/AvSzOBiCxZDyTTAoMfUGY6kfvBonWpwnZBfd7_MS6zH4kPI0mawqS4boPlJ6HINa5VHgWllr=s160-c-k-c0x00ffffff-no-rj" // Channel Image for Teddy and Timmy 3D
    },
    {
        title: "Mera Preschool",
        videoUrl: "https://www.youtube.com/watch?v=example_video_id",
        channelUrl: "https://youtube.com/@merapreschool?si=XFWlyUOsJXhbrR7h",
        channelImage: "https://yt3.googleusercontent.com/XPN_KGhidJZCWd5oezkaDkBdmFaLz1vKgJ0lnDUX-E6o7bOdyCUQNHnUaUKzxsooj2D8_G63yWQ=s160-c-k-c0x00ffffff-no-rj" // Channel Image for Teddy and Timmy 3D
    },
];
