import React from "react";
import { extractFileId } from "../utils/common";

const WeeklyPlanComponent = ({ title, weekNumber, path }) => {
    // const extractFileId = (url) => {
    //     const match = url.match(/\/d\/(.*?)\//);
    //     return match ? match[1] : null;
    //   };
    const fileId = extractFileId(path)
    const downloadUrl = `https://drive.google.com/uc?id=${fileId}&export=download`;

    return (
        <div className="max-w-xs w-full sm:w-1/2 md:w-1/3 lg:w-1/4 bg-white shadow-lg rounded-lg overflow-hidden p-4 m-2 flex flex-col items-start">
            <div
                className="w-full h-48 flex items-center justify-center bg-gray-200 text-gray-700 font-bold text-2xl"
            >
                Week {weekNumber}
            </div>
            {/* <h2 className="text-lg font-semibold text-gray-800 mb-2 text-center">
                {title}
            </h2> */}
            <div className="flex justify-between mt-2 w-full">
                <a
                    href={path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 text-sm transition-transform transform hover:scale-110 animate-fade-in"
                >
                    View
                </a>
                <a
                    href={downloadUrl}
                    download
                    className="text-blue-600 hover:text-blue-800 text-sm transition-transform transform hover:scale-110 animate-fade-in"
                >
                    Download
                </a>
            </div>
        </div>
    );
};

export default WeeklyPlanComponent;