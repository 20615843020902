export const gamesData = [
    {
        id: 1,
        title: "Animal Jam",
        thumbnail: require("../images/Animal Jam.jpeg"),
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.WildWorks.AnimalJamPlayWild&hl=en",
        appStoreLink: "https://apps.apple.com/us/app/animal-jam/id1003820457",
    },
    {
        id: 2,
        title: "Lingokids - Play and Learn",
        thumbnail: require("../images/Lingokids - Play and Learn.jpeg"),
        googlePlayLink: "https://play.google.com/store/apps/details?id=es.monkimun.lingokids&hl=en",
        appStoreLink: "https://apps.apple.com/us/app/lingokids-play-and-learn/id1002043426",
    },

    {
        id: 3,
        title: "Kids Puzzles Games Puzzingo",
        thumbnail: require("../images/Kids Puzzles Games Puzzingo.jpeg"),
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.sx.puzzingo&hl=en",
        appStoreLink: "https://apps.apple.com/us/app/kids-puzzles-games-puzzingo/id444722625",
    },
    {
        id: 4,
        title: "Learn to Read - Duolingo ABC",
        thumbnail: require("../images/Learn to Read - Duolingo ABC.jpeg"),
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.duolingo.literacy&hl=en",
        appStoreLink: "https://apps.apple.com/us/app/learn-to-read-duolingo-abc/id1440502568",
    },

    {
        id: 5,
        title: "Sago Mini World: Kids Games",
        thumbnail: require("../images/Sago Mini World.jpeg"),
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.sagosago.World.googleplay&hl=en",
        appStoreLink: "https://apps.apple.com/us/app/sago-mini-world-kids-games/id874425722?platform=iphone",
    },

    {
        id: 6,
        title: "PBS KIDS Games",
        thumbnail: require("../images/PBS KIDS Games.jpeg"),
        googlePlayLink: "https://play.google.com/store/apps/details?id=org.pbskids.gamesapp&hl=en",
        appStoreLink: "https://apps.apple.com/us/app/pbs-kids-games/id1050773989?platform=iphone",
    },
    {
        id: 7,
        title: "Starfall ABCs",
        thumbnail: require("../images/Starfall ABCs.jpeg"),
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.starfall.StarfallABCs&hl=en",
        appStoreLink: "https://apps.apple.com/us/app/starfall-abcs/id395623983",
    },
    {
        id: 9,
        title: "Khan Academy Kids",
        thumbnail: require("../images/Khan Academy Kids.jpeg"),
        googlePlayLink: "https://play.google.com/store/apps/details?id=org.khankids.android&referrer=utm_source%3Dwebsite%26utm_medium%3Dlandingpage%26utm_campaign%3Dkhankidslandingpage",
        appStoreLink: "https://apps.apple.com/us/app/khan-academy-kids/id1378467217",
    },

    {
        id: 10,
        title: "Busy Shapes & Colors",
        thumbnail: require("../images/Busy Shapes & Colors.jpeg"),
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.edokiacademy.busyshapesandcolors&hl=en",
        appStoreLink: "https://apps.apple.com/us/app/busy-shapes-colors/id1209114575",
    },
];