import { useLocation } from 'react-router-dom';
import ReactGA4 from 'react-ga4';
import { useEffect } from 'react';

export const usePageViewAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA4.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);
};
