import React from "react";
import SchoolTypeItem from "./SchoolTypeItem";
import { schooleTypeData } from "../assets/data/SchoolTypeData";

const SchoolTypeComponent = () => {
    return (
        <div className="min-h-screen bg-blue-100 flex items-center justify-center">
            <div className="max-w-4xl mx-auto text-center p-6">
                {/* Main Heading */}
                <h1 className="text-3xl md:text-5xl font-bold text-blue-900">
                    Let's learn together.
                </h1>

                {/* Green Underline */}
                <div className="w-64 h-1 bg-yellow-400 mx-auto my-8"></div>

                {/* School Type Section */}
                {schooleTypeData.map((type, index) => {
                    return (
                        <SchoolTypeItem
                            key={index}
                            img={type.image}
                            title={type.title}
                            desc={type.description}
                            path={type.path}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default SchoolTypeComponent;
