import React, { useState } from 'react';
import { learningToysData } from '../assets/data/LearningToysData';
import ScrollToTopButton from '../components/scrollTopButton';

function LearningToys() {
    const [expandedImage, setExpandedImage] = useState(null); // State for the enlarged image

    const handleImageClick = (photo) => {
        setExpandedImage(photo); // Set the clicked image as the enlarged image
    };

    const closeImage = () => {
        setExpandedImage(null); // Close the enlarged image view
    };

    return (
        <div className='mt-24'>
            <div className="p-4 grid gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {learningToysData.map((photo, index) => (
                    <div key={index} className="bg-white p-4 rounded shadow-md">
                        <img
                            src={photo.path}
                            alt={`Homeschooling ${photo.title} toy`}
                            style={{ width: '100%', height: '15rem', objectFit: 'cover' }}
                            className="cursor-pointer" // Add cursor pointer for clickable images
                            onClick={() => handleImageClick(photo)} // Click handler for enlarging image
                        />
                        <p style={{ marginTop: '0.2rem', fontSize: '1rem', color: '#333', textAlign: 'center' }}>
                            {photo.title}
                        </p>
                    </div>
                ))}
            </div>

            {/* Modal for enlarged image */}
            {expandedImage && (
                <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"> {/* Overlay */}
                    <div className="relative">
                        <img
                            src={expandedImage.path}
                            alt="Expanded"
                            className="max-w-[90vw] max-h-[90vh] object-contain rounded-md" // Ensure image fits within the viewport
                        />
                        <button
                            onClick={closeImage}
                            className="absolute top-4 right-4 text-white text-3xl font-bold"
                        >
                            &times; {/* Cross icon for closing the image */}
                        </button>
                    </div>
                </div>
            )}

            {/* Circular button to scroll to top */}
            <ScrollToTopButton />
        </div>
    );
}

export default LearningToys;
