import React from 'react';
import { PrintableData } from '../assets/data/PrintableData';
import PrintableItem from './PrintableItem';
import ScrollToTopButton from './scrollTopButton';

const PrintableContainer = ({ isMainPage }) => {
    return (
        <div className="flex flex-col items-center">
            <h1 className="text-4xl font-bold mt-8 underline decoration-black text-center">
                {isMainPage ? 'Free Preschool & Kindergarten Printables' : 'Printables'}
            </h1>

            <p className="text-lg mt-4 text-center px-4">
                {isMainPage ?
                    'Download fun, educational printables designed for preschoolers and kindergarteners! From alphabet and number activities to coloring pages, these resources make early learning engaging and enjoyable. Perfect for building essential skills at home or in the classroom.'
                    :
                    'It provides details about the content of the worksheet and instructions for use.'}
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-2 w-full max-w-screen-xl">
                {PrintableData.map((printable, index) => {
                    return (
                        <PrintableItem
                            key={index}
                            photos={printable.images}
                            path={printable.path}
                            text={printable.text}
                            button={printable.button}
                        />
                    );
                })}
            </div>

            {/* Circular button to scroll to top */}
            <ScrollToTopButton />
        </div>
    );
};

export default PrintableContainer;
