import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-black text-white py-6 mt-6">
            <div className="container mx-auto text-center">
                {/* Brand Name */}
                {/* Contact Information Section */}
                <div className="mb-6">
                    <p className="text-xl font-semibold mb-3">Contact Information</p>
                    <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-x-6 sm:space-y-0 text-sm">
                        <div>
                            <p>Email: <a href="mailto:homeschoolingwithmbu@gmail.com" className="text-blue-400 hover:text-blue-300">homeschoolingwithmbu@gmail.com</a></p>
                        </div>
                    </div>
                </div>

                {/* Other Information */}
                <div className="mt-4 text-sm">
                    <p className="text-gray-400">
                        Copyright © 2024 Umer Rasheed
                    </p>
                </div>

                {/* LinkedIn Link */}
                <div className="flex justify-center mt-4">
                    <a href="https://www.linkedin.com/in/umar-rasheed/?originalSubdomain=pk" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 mx-2">
                        LinkedIn
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

