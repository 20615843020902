import React from 'react';
import PrintableContainer from '../components/PrintableContainer';
import ActivitiesContainer from "../components/ActivitiesContainer";
import ScrollToTopButton from '../components/scrollTopButton';

const PreSchoolDetails = () => {

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
            <div className="w-full max-w-4xl rounded-lg overflow-hidden">
                <div className="container mx-auto p-4">
                    <h1 className="text-4xl font-bold mt-32">
                        What is Preschool?
                    </h1>
                    <p className="text-lg mt-2 ">
                        Preschool is an early childhood educational program designed for children typically between the ages of 2 and 4, before they enter formal schooling (kindergarten).
                        It provides young children with a structured environment where they can learn basic social, emotional, cognitive,
                        and motor skills through play-based activities.
                    </p>
                    <h2 className="text-4xl font-bold mt-10">
                        What Age Do Kids Start Preschool?
                    </h2>
                    <p className="text-lg mt-2 ">
                        Kids typically start preschool between the ages of 2 and 4 years old. The exact starting age can vary depending on the child's developmental readiness and the specific preschool program.
                        <p className="font-bold mb-1">2 to 3 years old:</p> Some preschools accept younger children around age 2, especially in programs that emphasize socialization, play-based learning, and basic skill-building.
                        <p className="font-bold mb-1">3 to 4 years old:</p> Most children start preschool at age 3 or 4. At this stage, they begin engaging more deeply in structured activities that promote language development, cognitive skills, fine motor, and social-emotional growth.<br />
                        The goal of preschool is to prepare children for kindergarten, so the timing can depend on their individual needs and the educational goals of the family.
                    </p>

                    <h1 className="text-4xl font-bold mt-10 text-blue-900">
                        Is My Child Ready for Preschool?
                    </h1>
                    <p className="text-lg mt-5 ">
                        To know if your child is ready for preschool, here are some key signs to consider:
                        <br /><p className="font-bold mb-1">Potty Training:</p>  Many preschools prefer children to be potty trained, or at least in the process.
                        <br /><p className="font-bold mb-1">Independence:</p> Your child can handle simple tasks like eating, dressing, or washing hands with little help.
                        <br /><p className="font-bold mb-1">Communication:</p> They can express their needs, follow simple directions, and understand basic questions.
                        <br /><p className="font-bold mb-1">Social Skills:</p> Your child enjoys playing with other kids, can share, and take turns.
                        <br /><p className="font-bold mb-1">Separation:</p> Your child can stay with other caregivers or teachers without being too distressed.
                        <br /> <br />If your child meets most of these, they may be ready for preschool!
                    </p>
                    <div className="flex flex-col sm:flex-row gap-8 mt-4 ">
                    </div>
                    <PrintableContainer />
                    <ActivitiesContainer />
                </div>
            </div>

            {/* Circular button to scroll to top */}
            <ScrollToTopButton />
        </div>
    );
};

export default PreSchoolDetails;
