import React from 'react';
import { learningVideos } from '../assets/data/youtubevideosData';

const VideoList = () => {

    return (
        <div className="p-6 mt-40">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                {learningVideos.map((video, index) => (
                    <a
                        key={index}
                        href={video.channelUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block bg-white p-4 shadow-md rounded-lg overflow-hidden" // Applied rounded-lg for border-radius
                        style={{ borderRadius: '10px' }}
                    >
                        {/* Channel image thumbnail */}
                        <div className="w-full overflow-hidden rounded-lg" style={{ borderRadius: '10px' }}>
                            <img
                                src={video.channelImage}
                                alt={`Free Homeschooling ${video.title} video channel`}
                                className="w-full h-48 object-cover rounded-lg"
                                style={{ borderRadius: '10px 10px 0 0' }} // Rounded top for image only
                            />
                        </div>

                        {/* Channel title */}
                        <h3 className="text-lg font-semibold mt-4 text-center">
                            {video.title}
                        </h3>
                    </a>
                ))}
            </div>
        </div>

    );
};

export default VideoList;
