// pictureData.js
export const pictureData = [
    {
        title: 'Pipe cleaner',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730457713/IMG_8201_kdmnox.jpg",
    },
    {
        title: 'Transffering activity',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195674/IMG_8182_wj19w3.jpg",
    },
    {
        title: 'Peeka boo',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730187937/3b71342c-af09-457b-9a25-3af6faedf426_vu1p4l.jpg",
    },
    {
        title: 'Color Matching',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730445522/IMG_7966_a7jn0d.jpg",
    },
    {
        title: 'Fishing game',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195673/IMG_8216_ajoflh.jpg",
    },
    {
        title: 'Block building',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195675/IMG_8231_odqeag.jpg"
    },
    {
        title: "title",
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195673/IMG_8323_w7krad.jpg"

    },
    {
        title: "title",
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195675/IMG_8284_nfzwjy.jpg",

    },
    {
        title: 'Sorting shapes',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467184/IMG_6514_fyzsb7.jpg",
    },
    {
        title: 'Two piceces Flash cards',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467185/IMG_6622_r1okoq.jpg",
    },
    {
        title: 'Block building',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195675/IMG_8231_odqeag.jpg"
    },
    {
        title: 'Cotton ball sheep',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195920/IMG_7491_vtskk8.jpg",
    },
    {
        title: 'Multiple pieces Puzzle',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730458940/IMG_8181_cejwth.jpg",
    },
    {
        title: '2-pieces Puzzle',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730464871/IMG_8362_dlci3o.jpg",
    },
    {
        title: 'Threading pasta',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730464847/IMG_8357_wnq4fg.jpg",
    },
    {
        title: 'Sorting shapes',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467183/IMG_6519_mgvpvc.jpg",
    },
    {
        title: 'Smelling flower',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467184/IMG_6535_kbm6cz.jpg",
    },
    {
        title: 'Fitting pipes',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467709/IMG_8368_qbd3og.jpg",
    },
    {
        title: 'Book reading',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195486/IMG_8290_kuwlgv.jpg"
    },
    {
        title: 'Identifying Flash cards',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467184/IMG_6629_o9z8ec.jpg"
    },
    {
        title: 'Grab animals',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730483490/IMG_7740_tdo2io.jpg",
    },
];
