import React from "react";

const PrintableItem = ({ photos, text, button }) => {
    return (
        <div className="flex flex-col items-center">
            {/* Three Images in a Row with Individual Text */}
            <div className="flex space-x-7 p-4 items-center justify-center">
                {/* Image 1 */}
                <div className="text-center flex flex-col items-center">
                    <img
                        src={photos}
                        alt={`Free homeschool ${text}`}
                        className="w-72 h-52 rounded-lg"
                    />
                    <div className="mt-2 text-center">
                        <p className="text-sm text-gray-700">{text}</p>
                        <div className="mt-2 flex items-center space-x-2">
                            <a href={button} target="_blank"
                                rel="noopener noreferrer" className="text-blue-500 hover:underline">View</a>
                            <span>|</span>
                            <a href={button} target="_blank" download className="text-blue-500 hover:underline">Download</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default PrintableItem;
