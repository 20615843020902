import React, { useEffect, useState } from 'react';

const ActivityItem = ({ title, description, photos, videos, activityDetails = [] }) => {
    const [activeTab, setActiveTab] = useState('photos');
    const [expanded, setExpanded] = useState(false);
    const [expandedImage, setExpandedImage] = useState(null); // State for the expanded image
    const initialLength = activityDetails.length > 0 && activityDetails.length <= 3 ? activityDetails : activityDetails.slice(0, 3);
    const [detailsToShow, setDetailsToShow] = useState(initialLength);

    useEffect(() => {
        if (expanded) {
            setDetailsToShow(activityDetails);
        } else {
            setDetailsToShow(activityDetails.slice(0, 3));
        }
    }, [expanded, activityDetails]);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const handleImageClick = (photo) => {
        setExpandedImage(photo);
    };

    const closeImage = () => {
        setExpandedImage(null);
    };

    return (
        <div className='mr-8 lg:mr-32 w-full lg:w-90'>
            <h1 className="text-4xl font-bold mt-8 underline decoration-black">{title}</h1>
            <p className="text-lg mt-4">{description}</p>

            {detailsToShow.map((detail, index) => (
                <div key={index} className="mt-4">
                    <span className="font-bold">{index + 1}. </span>
                    <span className="font-bold">{detail.title}</span>
                    <p className="mt-3">{detail.details}</p>
                </div>
            ))}

            {activityDetails.length > 3 && (
                <button
                    onClick={toggleExpand}
                    className="mt-4 text-blue-500 underline"
                >
                    {expanded ? 'Show Less' : 'Read More'}
                </button>
            )}

            <div className="flex space-x-4 mb-4 mr-32 mt-10">
                {photos.length > 0 && (
                    <button
                        className={`py-2 px-4 rounded ${activeTab === 'photos' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                        onClick={() => setActiveTab('photos')}
                    >
                        Photos
                    </button>
                )}
                {videos.length > 0 && (
                    <button
                        className={`py-2 px-4 rounded ${activeTab === 'videos' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                        onClick={() => setActiveTab('videos')}
                    >
                        Videos
                    </button>
                )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {activeTab === 'photos' && photos.length > 0 ? (
                    photos.map((photo, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <img
                                src={photo.path}
                                alt={`homeschooling ${photo.title} activity`}
                                className="w-full h-60 object-cover rounded-md cursor-pointer" // Add cursor-pointer for clickable images
                                onClick={() => handleImageClick(photo)} // Click handler for image
                            />
                            <p className="mt-2 text-center text-gray-700">{photo.title}</p>
                        </div>
                    ))
                ) : activeTab === 'videos' && videos.length > 0 ? (
                    videos.map((video, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <video
                                src={video.path}
                                alt={`homeschooling ${video.title} activity`}
                                controls
                                className="w-full h-60 object-cover rounded-md" // Consistent styling for videos
                            />
                            <p className="mt-2 text-center text-gray-700">{video.title}</p>
                        </div>
                    ))
                ) : (
                    <p>No media available</p>
                )}
            </div>

            {/* Modal for enlarged image */}
            {expandedImage && (
                <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"> {/* Overlay */}
                    <div className="relative">
                        <img
                            src={expandedImage.path}
                            alt="Expanded"
                            className="max-w-full max-h-screen"
                        />
                        <button
                            onClick={closeImage}
                            className="absolute top-4 right-4 text-white text-3xl"
                        >
                            &times; {/* Cross icon for closing the image */}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ActivityItem;
