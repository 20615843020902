export const learningToysData = [
    {
        title: 'Magnetic tablet',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730642159/IMG_8404_qq6nyg.jpg",
    },
    {
        title: 'Islamic educational tab',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730642160/IMG_8405_ofrpdt.jpg",
    },
    {
        title: 'Cube puzzle',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641060/IMG_8378_s6r2fs.jpg",
    },
    {
        title: 'Pipe building blocks',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641060/IMG_8379_hrpepu.jpg",
    },
    {
        title: 'Elephant puzzle',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641060/IMG_8380_oygawi.jpg",
    },
    {
        title: 'Tools',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641060/IMG_8384_c75m5y.jpg",
    },
    {
        title: 'Snow flower building block',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641060/IMG_8383_aft8hs.jpg",
    },
    {
        title: 'Popit bubble',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641060/IMG_8389_wayvea.jpg"
    },
    {
        title: "Ring Stack",
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641059/IMG_8386_tj13az.jpg"

    },
    {
        title: "Cars",
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641058/IMG_8390_qxbzdq.jpg",

    },
    {
        title: 'Animals',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641060/IMG_8382_xit6ea.jpg",
    },
    {
        title: 'Dream block',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641059/IMG_8402_p3sh3c.jpg",
    },
    {
        title: 'Lovely blocks',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641058/IMG_8392_dmh0dg.jpg"
    },
    {
        title: 'Magic drawing board',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641057/IMG_8397_jxcwte.jpg",
    },
    {
        title: 'Block puzzle',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641057/IMG_8399_zxlkxr.jpg",
    },
    {
        title: 'Magic clay',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641057/IMG_8400_hi6chf.jpg",
    },
    {
        title: 'Draw with me',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641057/IMG_8398_b9mmax.jpg",
    },
    {
        title: 'Number block',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641057/IMG_8393_xsbuxg.jpg",
    },
    {
        title: 'Electronic study book',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641057/IMG_8394_sktmfr.jpg",
    },
    {
        title: 'Magnetic',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641056/IMG_8403_jwstvo.jpg",
    },
    {
        title: 'Fishing game',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641059/IMG_8391_wvtgav.jpg"
    },
    {
        title: 'Dump Truck',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641058/IMG_8388_oumtdp.jpg"
    },
    {
        title: 'Wooden shapes',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641530/IMG_6514_bqephc.jpg",
    },
    {
        title: 'Pattern tracing stencil',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641531/IMG_8224_e97yji.jpg",
    },
    {
        title: 'LCD writing tablet',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641685/IMG_7584_c4x5ve.jpg",
    },
    {
        title: 'Wooden alphabets',
        path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730641689/IMG_6621_hsw63j.jpg",
    },
];
