import React from 'react';
const SchoolTypeItem = ({ img, title, desc, path }) => {
    return (
        <div className="mt-14 flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/3 w-full mt-16">
                <a href={path}><img src={img} className="rounded-3xl" /></a>
            </div>
            <div className="md:w-2/3 w-full md:ml-10 mt-6 md:mt-0 text-left">
                <h2 className="text-2xl font-bold text-blue-900">{title}</h2>
                <p className="text-gray-700 mt-4">{desc}</p>
                <a
                    href={path}
                    className="inline-block mt-4 text-blue-500 font-bold hover:underline"
                >
                    Learn more &gt;
                </a>
            </div>
        </div>
    );
};

export default SchoolTypeItem;
