import React, { useState } from 'react';
import { pictureData } from '../assets/data/pictureData';
import ScrollToTopButton from '../components/scrollTopButton';

function PicturePage() {
    const [expandedImage, setExpandedImage] = useState(null);

    const handleImageClick = (photo) => {
        setExpandedImage(photo);
    };

    const closeImage = () => {
        setExpandedImage(null);
    };

    return (
        <div className="mt-24">
            <div className="p-4 grid gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {pictureData.map((photo, index) => (
                    <div key={index} className="bg-white p-4 rounded shadow-md relative">

                        {/* Image */}
                        <img
                            src={photo.path}
                            alt={`Homeschooling ${photo.title} activity`}
                            style={{ width: '100%', height: '15rem', objectFit: 'cover' }}
                            className="cursor-pointer"
                            onClick={() => handleImageClick(photo)}
                        />

                        <p className="mt-2 text-gray-800 text-center">
                            {photo.title}
                        </p>
                    </div>
                ))}
            </div>

            {/* Modal for enlarged image */}
            {expandedImage && (
                <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
                    <div className="relative mt-28">
                        <img
                            src={expandedImage.path}
                            alt={`Homeschooling ${expandedImage.title} activity`}
                            className="max-w-[90vw] max-h-[90vh] object-contain rounded-md"
                        />
                        <button
                            onClick={closeImage}
                            className="absolute top-4 right-4 text-white text-3xl font-bold"
                        >
                            &times;
                        </button>
                    </div>
                </div>
            )}

            {/* Circular button to scroll to top */}
            <ScrollToTopButton />
        </div>
    );
}

export default PicturePage;
