import React from "react";
import CurriculumComponent from "../components/CurriculumComponent";
import { curriculumData } from "../assets/data/CurriculumData";
import BooksDataComponent from "../components/BooksDataComponent";
import { booksData } from "../assets/data/booksData";
import ScrollToTopButton from "../components/scrollTopButton";

const CurriculumCombineBooks = () => {
    return (
        <div className="mt-44"> {/* Adjust the top margin */}
            {/* Heading with underline */}
            {/* <h1 className="text-3xl font-bold text-gray-800 mb-1 text-center md:text-left md:ml-20 animate-fade-in">
                Curriculum
            </h1> */}
            <div className="text-center md:text-left md:ml-12">
                <h1 className="text-2xl font-bold mb-2">Curriculum</h1>
                <span className="block w-32 h-1 bg-gradient-to-r from-blue-500 to-purple-500 mt-1 mb-4 mx-auto md:mx-0"></span>
            </div>

            {/* Line below heading */}
            {/* <span className="block w-44 h-1 bg-gradient-to-r from-blue-500 to-purple-500 mt-2 mx-auto md:mx-0 md:w-32 md:mt-2"></span> */}

            {/* Single Image for Curriculum with centered alignment on mobile */}
            <div className="flex flex-wrap gap-4 justify-center md:justify-start md:ml-12"> {/* Center images on mobile */}
                {curriculumData.map((pdf, index) => (
                    <CurriculumComponent
                        key={index}
                        title={pdf.title}
                        thumbnail={pdf.thumbnail}
                        path={pdf.path}
                    />
                ))}
            </div>

            {/* Books Section */}
            <div className="p-4 mt-8">
                {/* Books Heading and Underline */}
                <div className="text-center md:text-left md:ml-8">
                    <h1 className="text-2xl font-bold mb-2">Books</h1>
                    <span className="block w-16 h-1 bg-gradient-to-r from-blue-500 to-purple-500 mt-1 mb-4 mx-auto md:mx-0"></span>
                </div>

                {/* Responsive Grid for Books */}
                <div className="flex flex-wrap gap-4 justify-center md:justify-center">
                    {booksData.map((book, index) => (
                        <BooksDataComponent
                            key={index}
                            title={book.title}
                            thumbnail={book.thumbnail}
                            path={book.path}
                        />
                    ))}
                </div>
            </div>
            {/* Circular button to scroll to top */}
            <ScrollToTopButton />
        </div>
    );
};

export default CurriculumCombineBooks;
