
export const videoData = [

    {
        title: "Pipe cleaner",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730357908/video-output-34AF3154-6993-45B1-9E38-EB865F7922E7_a46d8f.mov`,

    },
    {
        title: "Peeka boo",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445047/video-output-0BD2C30D-CDF0-4591-9E16-22257E1F3A60_sifyis.mov`,

    },
    {
        title: "Feeding the chick",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730358124/video-output-FC79A430-197B-467C-BE9C-4EDE365A699B_uwywv1.mov`,

    },
    {
        title: "Magnetic tab",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730357608/video-output-1AC6266D-9A7A-45D8-BAAA-5F09B6D67BE5_bbkkb5.mov`,

    },
    {
        title: "Egg smashing",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445053/video-output-9BF03E4C-331E-4F83-860B-B307F2941AD4_g8jxgs.mov`,

    },
    {
        title: "Peg activity",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445074/video-output-E61C71C1-92FB-4C50-A017-C62F277F048F_lujppe.mov`,

    },
    {
        title: "Color recognition",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445099/video-output-534B268B-E153-4F10-81AA-AFCE34B44406_bvqpmg.mov`,

    },
    {
        title: "Peg activity",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445074/video-output-E61C71C1-92FB-4C50-A017-C62F277F048F_lujppe.mov`,

    },
    {
        title: "Water play",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445085/257cec7a3e48409ba5ab655e837804bf_ec68fr.mov`,

    },
    {
        title: "Busy binder",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730459999/video-output-38E9277C-DD37-45BA-95BA-AE314A629BB6_gqztoq.mov`,

    },
    {
        title: "Problem solving",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730470213/video-output-C094E6AB-BC51-412A-AF91-B2D11ACF0D78_elcxlg.mov`,

    },
    {
        title: "Pipe joints",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730485584/video-output-2A6F51F9-6337-4B8A-9156-0BBBAB7C7DD0_yrwexh.mov`,

    },
    {
        title: "Magnetic tab",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730357608/video-output-1AC6266D-9A7A-45D8-BAAA-5F09B6D67BE5_bbkkb5.mov`,

    },
    {
        title: "Identifying alphabets",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730357887/video-output-0D9D19D4-9494-404F-96B0-A3B8790C7C5F_vlpdih.mov`,

    },
    {
        title: "Story book",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730358099/video-output-57E9DCFA-D0E0-4727-8BCF-FC888E05AAF4_idpjup.mov`,

    },
    {
        title: "Hungery cat",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730468823/video-output-DE5A1570-8305-4941-8802-E987749CE6E9_j2hgj9.mov`,

    },
    {
        title: "Busy binder",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730459999/video-output-38E9277C-DD37-45BA-95BA-AE314A629BB6_gqztoq.mov`,

    },

    {
        title: "Water play",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445085/257cec7a3e48409ba5ab655e837804bf_ec68fr.mov`,

    },
    {
        title: "Hungery cat",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730468823/video-output-DE5A1570-8305-4941-8802-E987749CE6E9_j2hgj9.mov`,

    },
    {
        title: "Sticky wall ball",
        path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730491314/video-output-728A56B1-8A49-4813-9EA4-16F43F77D9E7_e6cuu5.mov`,

    },
];
