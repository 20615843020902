export const curriculumData = [

    {
        path: require("../pdfs/curiculum/PRESCHOOL CURRICULUM - YEAR 2 - 6.pdf"),
        title: `PRESCHOOL CURRICULUM - YEAR 2 - 6`,
        thumbnail: require("../images/curriculum.jpeg")
    },

   
   
    
]