import React from 'react';
import { videoData } from '../assets/data/videoData';
import ScrollToTopButton from '../components/scrollTopButton';

const VideoPage = () => {
    return (
        <div className="p-6 mt-32">
            <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {videoData.map((video, index) => (
                    <div key={index} className="w-full max-w-xs mx-auto text-center">
                        <div className="aspect-w-16 aspect-h-9">
                            <video
                                src={video.path}
                                controls
                                className="w-full h-80 object-contain bg-gray-200 rounded-md"
                            />
                        </div>
                        <p className="mt-2 text-lg text-gray-700">{video.title}</p>
                    </div>
                ))}
            </div>

            {/* Circular button to scroll to top */}
            <ScrollToTopButton />
        </div>
    );
};

export default VideoPage;
