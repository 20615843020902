export const booksData = [
    {
        path: "https://drive.google.com/file/d/1OvYYsbNl97HxtiWV1RtqDTwP3Q2kdEDL/view?usp=drivesdk",
        title: "A Cat Can Sit",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1Otd7jqbrW6XyH305DMomWvt7V94SRQL4/view?usp=drivesdk",
        title: "A Dog Can Sit",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1PPVzicUcDn6VTuy2k9pgmJZwvPuiKVj9/view?usp=drivesdk",
        title: "A MESS",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1Ot8h_ddJ-gbXiEOHYhuVblA7avFe3Z8d/view?usp=drivesdk",
        title: "A Silly Day at School",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1PED2U8rFQXiSax8trv9a6KEqOShl5aEx/view?usp=drivesdk",
        title: "A Spot To Nap",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1Osw4z4TVOCEa7EKgMx4y6Y5PvqrwE-fi/view?usp=drivesdk", // Use an empty string for no path
        title: "A Tractor Can",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1PC2-L1tgzvpNnCFFEtDgLxtj4whpG5GP/view?usp=drivesdk",
        title: "Big Fish And Little Fish",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1OsQnmHBmaUL8K6hqFMj8RcuLcmdLL4si/view?usp=drivesdk",
        title: "Alligator",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1P0nomhowMW1jI3tXpfrUfQq8jiyb2smT/view?usp=drivesdk",
        title: "The Brown Rabbit",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1P22Y6yEB9EQ83GjnFpOIIYKJmuR8IqWK/view?usp=drivesdk",
        title: "Dogs and Cats",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1OyklyHPFtz4BR0-QxWJeq8hiLVUHErC9/view?usp=drivesdk",
        title: "In My Graden",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1P-hnSNA44NdgL1kHLDhLXrmKkCBBBRfa/view?usp=drivesdk",
        title: "Lost Toy",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1PBYB8Uf5L2GK8BAZma_A3xEcdZzJWWYt/view?usp=drivesdk",
        title: "The Cap",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1P5gSAGdNjk580N1X34BIHjefdrXf6x4B/view?usp=drivesdk",
        title: "The Nest",
        thumbnail: require("../images/books-cover.jpeg")
    },
    {
        path: "https://drive.google.com/file/d/1OqO8fj7heOS5tm60J9nQhmx4pAtHYJ2l/view?usp=drivesdk",
        title: "The Seal & The Sharky",
        thumbnail: require("../images/books-cover.jpeg")
    },
];
