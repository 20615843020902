export const ActivitiesData = [
    {
        title: "Fine Motor Activities",
        description: `Here are some fun fine motor activities for preschoolers:`,
        activityDetails: [
            { title: 'Playdough Shapes', details: 'Children can use playdough to create different shapes, letters, or animals. This strengthens their finger muscles and encourages creativity.' },
            { title: 'Cutting Practice', details: 'Offer child-safe scissors and paper with pre-drawn lines or shapes for children to cut out, helping to develop precision and control.' },

            {
                title: 'Puzzles', details: "Age-appropriate puzzles help improve fine motor control while engaging children's problem-solving skills."
            },
            {
                title: 'Clothespin Pinching',
                details: "Let children practice squeezing and pinching clothespins onto a string or edge of a box. This helps build finger strength."
            },
            {
                title: 'Paper Tearing',
                details: "Encourage children to tear paper into strips or shapes. It's a great way to strengthen hand muscles and improve coordination."
            },
            {
                title: 'Pom-Pom Transfer',
                details: "Using a spoon, children can transfer pom-poms from one bowl to another. This enhances hand stability and grip control."
            },
            {
                title: 'Finger Painting',
                details: "Let kids paint using their fingers instead of brushes. It's a fun, sensory way to develop hand dexterity while being creative."
            },
            {
                title: 'Hole Punching',
                details: "Provide a hole puncher and paper for children to punch holes. It's an excellent way to strengthen hand muscles."
            },
            {
                title: 'Peeling Tape',
                details: "Stick pieces of masking tape on a table and let kids peel them off. This helps with finger control and grip."
            },
            {
                title: 'Dot Marker Art',
                details: "Give kids dot markers to create art on a page. It requires control and improves hand strength as they press and dot."
            },
            {
                title: 'Pipe Cleaner Creations',
                details: "Let kids bend and twist pipe cleaners to create shapes, letters, or simple objects. This develops fine motor strength and creativity."
            },
            {
                title: 'Threading Pasta',
                details: "Let children thread dry pasta onto a string or pipe cleaner. It’s great for hand-eye coordination and fine motor precision."
            },
            {
                title: 'Sponge Squeezing',
                details: "In a water play activity, let kids squeeze water out of sponges. This helps strengthen their hand muscles."
            },
            {
                title: 'Cat Eats Beans',
                details: "In this imaginative fine motor activity, children pretend to feed a toy cat beans using a spoon. The act of scooping and carefully feeding the 'cat' enhances hand-eye coordination, grip strength, and control. It's a fun way to develop fine motor skills while engaging in pretend play."
            },
            {
                title: 'Peek-a-Boo',
                details: "In this fine motor activity, children use a toy hammer to tap objects that 'pop up' in a peek-a-boo style game. The hammering action helps develop hand-eye coordination, precision, and grip strength, making it a playful and engaging way to build fine motor skills."
            },


        ],

        images: [
            {
                title: 'Pipe cleaner',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730457713/IMG_8201_kdmnox.jpg",
            },
            {
                title: 'Transffering activity',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195674/IMG_8182_wj19w3.jpg",
            },
            {
                title: 'Peeka boo',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730187937/3b71342c-af09-457b-9a25-3af6faedf426_vu1p4l.jpg",
            },
            {
                title: 'Cotton ball sheep',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195920/IMG_7491_vtskk8.jpg",
            },
            {
                title: 'Color Matching',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730445522/IMG_7966_a7jn0d.jpg",
            },
            {
                title: 'Fishing game',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195673/IMG_8216_ajoflh.jpg",
            },
            {
                title: 'Ball drop activity',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195675/IMG_8284_nfzwjy.jpg",
            },
            {
                title: 'Block building',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195675/IMG_8231_odqeag.jpg"
            }
        ],

        videos: [
            {
                title: "Pipe cleaner",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730357908/video-output-34AF3154-6993-45B1-9E38-EB865F7922E7_a46d8f.mov`,

            },
            {
                title: "Peeka boo",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445047/video-output-0BD2C30D-CDF0-4591-9E16-22257E1F3A60_sifyis.mov`,

            },
            {
                title: "Feeding the chick",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730358124/video-output-FC79A430-197B-467C-BE9C-4EDE365A699B_uwywv1.mov`,

            },
            {
                title: "Magnetic tab",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730357608/video-output-1AC6266D-9A7A-45D8-BAAA-5F09B6D67BE5_bbkkb5.mov`,

            },
            {
                title: "Egg smashing",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445053/video-output-9BF03E4C-331E-4F83-860B-B307F2941AD4_g8jxgs.mov`,

            },
            {
                title: "Peg activity",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445074/video-output-E61C71C1-92FB-4C50-A017-C62F277F048F_lujppe.mov`,

            },
            {
                title: "Color recognition",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445099/video-output-534B268B-E153-4F10-81AA-AFCE34B44406_bvqpmg.mov`,

            },

        ]
    },
    {
        title: "Gross Motor Activities",
        description: `Gross motor skills are the abilities required to control the large muscles of the body for movement and coordination. These skills involve actions like walking, running, jumping, climbing, and balancing, which help children perform everyday activities and engage in physical play. Here are some fun gross motor activities that can help build strength, coordination, and balance:`,
        images: [
            {
                title: "Sticky wall ball",
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195673/IMG_8323_w7krad.jpg"

            },
            {
                title: "Ball drop activity",
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195675/IMG_8284_nfzwjy.jpg",

            },
            {
                title: 'Sorting shapes',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467184/IMG_6514_fyzsb7.jpg",
            },
            {
                title: 'Two piceces Flash cards',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467185/IMG_6622_r1okoq.jpg",
            },
        ],
        videos: [
            {
                title: "",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730491314/video-output-728A56B1-8A49-4813-9EA4-16F43F77D9E7_e6cuu5.mov`,

            },
            {
                title: "",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730490960/video-output-26666EFC-A3B2-4F33-9C2F-74B4E69CB3D1_bmewke.mov`,

            },
        ],
        activityDetails: [
            { title: 'Obstacle Course', details: ' Set up simple obstacles using pillows, tunnels, and cones. Encourage climbing, crawling, and jumping.' },
            { title: 'Balance Beam', details: 'Create a line on the floor with tape and let them walk along it to practice balance.' },
            { title: 'Throwing and Catching Balls', details: 'Use a soft ball to improve hand-eye coordination.' },
            { title: 'Jumping', details: 'Practice jumping over small objects or into hoops.' },
            { title: 'Climbing', details: ' Let them explore low playground structures or climbing frames.' },
            { title: 'Kicking a Ball', details: 'A great activity for learning coordination and timing.' },
            { title: 'Push and Pull Toys', details: 'Let them use toy wagons or push cars, which help develop leg and arm strength.' },
            { title: 'Balance on One Foot', details: 'A simple activity that helps improve balance and stability.' },
            { title: 'Pillow Jumping', details: 'Set up pillows in a line for them to jump over or onto, practicing control and agility.' },
            { title: 'Rolling a Ball Back and Forth', details: 'Sit on the floor and roll a ball to each other, working on arm strength and timing.' },
            { title: 'Animal Walks', details: 'Encourage them to walk like different animals – hop like a frog, waddle like a duck, or crawl like a bear.' },
        ]
    },
    {
        title: "Cognitive Skill Activities",
        description: `Cognitive development refers to the growth of a child’s ability to think, understand, reason, and remember. It involves acquiring skills like problem-solving, memory, language, attention, and decision-making, which all contribute to how a child interacts with the world. This type of development is crucial in the early years as it lays the foundation for learning and adapting as they grow. Cognitive Development Activities for Different Age Groups. For Babies and Toddlers.`,
        activityDetails: [
            { title: 'Peek-a-Boo', details: 'This classic game helps with memory and understanding object permanence (knowing things exist even when out of sight).' },
            { title: 'Sorting Colors and Shapes', details: 'Use shape sorters or colored blocks to help them recognize and categorize objects.' },
            { title: 'Cause-and-Effect Toy', details: 'Toys that respond to actions (like pressing a button to make a sound) help babies understand cause and effect.' },
            { title: 'Simple Puzzles', details: 'Age-appropriate puzzles develop problem-solving and hand-eye coordination.' },
            { title: 'Hide and Seek with Objects', details: 'Hide a toy under a cloth and encourage your child to find it, enhancing memory and tracking skills.' },
            { title: 'Matching Games', details: 'Use memory cards or picture matching to enhance memory and attention to detail.' },
            { title: 'Building with Blocks', details: 'Constructing with blocks or Legos develops spatial awareness, creativity, and fine motor skills.' },
            { title: 'Storytelling with Pictures', details: 'Use picture books to help kids identify and describe what’s happening, which builds language and comprehension.' },
            { title: 'Pretend Play', details: 'Role-playing with dolls or action figures helps kids understand social cues and practice language.' },
            { title: 'Simple Counting Games', details: 'Count toys or snacks to build early math skills.' },
        ],
        images: [
            {
                title: 'Block building',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195675/IMG_8231_odqeag.jpg"
            },
            {
                title: 'Cotton ball sheep',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195920/IMG_7491_vtskk8.jpg",
            },
            {
                title: 'Multiple pieces Puzzle',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730458940/IMG_8181_cejwth.jpg",
            },
            {
                title: '2-pieces Puzzle',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730464871/IMG_8362_dlci3o.jpg",
            },
            {
                title: 'Threading pasta',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730464847/IMG_8357_wnq4fg.jpg",
            },
            {
                title: 'Sorting shapes',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467183/IMG_6519_mgvpvc.jpg",
            },
            {
                title: 'Smelling flower',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467184/IMG_6535_kbm6cz.jpg",
            },
            {
                title: 'Fitting pipes',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467709/IMG_8368_qbd3og.jpg",
            },
        ],
        videos: [
            {
                title: "Peg activity",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445074/video-output-E61C71C1-92FB-4C50-A017-C62F277F048F_lujppe.mov`,

            },
            {
                title: "Water play",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445085/257cec7a3e48409ba5ab655e837804bf_ec68fr.mov`,

            },
            {
                title: "Busy binder",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730459999/video-output-38E9277C-DD37-45BA-95BA-AE314A629BB6_gqztoq.mov`,

            },
            {
                title: "Problem solving",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730470213/video-output-C094E6AB-BC51-412A-AF91-B2D11ACF0D78_elcxlg.mov`,

            },
            {
                title: "Pipe joints",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730485584/video-output-2A6F51F9-6337-4B8A-9156-0BBBAB7C7DD0_yrwexh.mov`,

            }

        ],
    },
    {
        title: "Language Development Activities",
        description: `Language development is the process through which children learn to communicate through sounds, words, sentences, and eventually conversation. This includes understanding and using vocabulary, grammar, and the social rules of language. Language skills are essential for self-expression, social interaction, and academic success.`,
        activityDetails: [
            { title: 'Talking and Narrating', details: 'Describe daily activities (e.g., “Now we’re putting on your shoes”) to help babies associate words with actions and objects.' },
            { title: 'Nursery Rhyme', details: 'Rhymes like “Twinkle, Twinkle, Little Star” introduce rhythm, vocabulary, and sound patterns.' },
            { title: 'Pointing and Naming Objects', details: 'Point to objects, animals, or body parts, and name them to build vocabulary.' },
            { title: 'Reading Simple Books', details: 'Choose books with bright pictures and simple text to encourage word recognition and interest in reading.' },
            { title: 'Animal Sounds', details: 'Practice saying animal sounds (e.g., “What does a cow say?”) to teach sound patterns and associations.' },
            { title: 'Storytime', details: 'Read books aloud and ask questions like “What do you think will happen next?” to develop comprehension and imagination.' },
            { title: 'Describe and Guess Game', details: 'Describe a familiar object without naming it (e.g., “It’s yellow, long, and you peel it to eat”) and let your child guess.' },
            { title: 'Role-Playing', details: 'Engage in pretend play (e.g., “Let’s play grocery store”) to encourage conversational skills, vocabulary, and sentence-building.' },
        ],
        images: [
            {
                title: 'Book reading',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195486/IMG_8290_kuwlgv.jpg"
            },
            {
                title: 'Memory cards',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730464871/IMG_8362_dlci3o.jpg"
            },
            {
                title: 'Identifying Flash cards',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467184/IMG_6629_o9z8ec.jpg"
            },
        ],
        videos: [
            {
                title: "Magnetic tab",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730357608/video-output-1AC6266D-9A7A-45D8-BAAA-5F09B6D67BE5_bbkkb5.mov`,

            },
            {
                title: "Identifying alphabets",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730357887/video-output-0D9D19D4-9494-404F-96B0-A3B8790C7C5F_vlpdih.mov`,

            },
            {
                title: "Story book",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730358099/video-output-57E9DCFA-D0E0-4727-8BCF-FC888E05AAF4_idpjup.mov`,

            },
            {
                title: "Hungery cat",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730468823/video-output-DE5A1570-8305-4941-8802-E987749CE6E9_j2hgj9.mov`,

            },
            {
                title: "Busy binder",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730459999/video-output-38E9277C-DD37-45BA-95BA-AE314A629BB6_gqztoq.mov`,

            }
        ],
    },
    {
        title: "Sensory Activities",
        description: `Sensory art activities engage children’s senses—touch, sight, smell, sound, and sometimes even taste! These activities are excellent for creativity and fine motor skills, and they also provide a calming and therapeutic outlet. 
Here are some sensory art ideas suitable for toddlers and preschoolers. For Toddlers and Preschoolers`,
        activityDetails: [
            { title: 'Finger Painting', details: 'Use washable, non-toxic paint and let them explore colors and textures with their hands. For added sensory experience, you can add sand or glitter to the paint.' },
            { title: 'Edible Play Dough', details: 'Make homemade play dough (using flour, water, and food coloring) for safe, hands-on sculpting. Add scents like vanilla or cocoa powder for a sensory boost.' },
            { title: 'Ice Cube Painting', details: 'Freeze colored water in ice cube trays with popsicle sticks and use them to paint, giving a cooling, melting sensory experience.' },
            { title: 'Sensory Bins', details: 'Fill bins with items like rice, beans, pasta, or sand. Hide small toys inside for kids to find by touch.' },
            { title: 'Color Mixing in Zip Bags', details: 'Place different colors of paint in a zip-top bag, seal it, and let kids squish the bag to see colors mix.' },
            { title: 'Listening Walk', details: 'Take a walk and ask kids to identify sounds they hear, like birds chirping, cars, or leaves rustling.' },
            { title: 'Homemade Popsicles', details: 'Make fruit popsicles together, allowing them to explore flavors and textures as they eat.' },
            { title: 'Mirror Painting', details: 'Use washable paint on a mirror or shiny surface. Kids can see reflections and work with colors and shapes.' },
        ],
        images: [
            {
                title: 'Fluffy sheep',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730195920/IMG_7491_vtskk8.jpg",
            },
            {
                title: 'Threading pasta',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730464847/IMG_8357_wnq4fg.jpg",
            },
            {
                title: 'Smelling flower',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730467184/IMG_6535_kbm6cz.jpg",
            },
            {
                title: 'Grab animals',
                path: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1730483490/IMG_7740_tdo2io.jpg",
            },

        ],
        videos: [
            {
                title: "Learning shapes ",
                embed: `<iframe src="https://www.instagram.com/reel/C-fHUEGgnPz/embed" width="400" height="500" frameborder="0" scrolling="no" allowtransparency="true" allow="encrypted-media" allowfullscreen></iframe>`

            },
            {
                title: "Water play",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730445085/257cec7a3e48409ba5ab655e837804bf_ec68fr.mov`,

            },
            {
                title: "Hungery cat",
                path: `https://res.cloudinary.com/dgybxrkh2/video/upload/v1730468823/video-output-DE5A1570-8305-4941-8802-E987749CE6E9_j2hgj9.mov`,

            }
        ],
    }

];
